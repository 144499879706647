<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'base.filter' | translate }}
    v-card-content
      .cols-x
        .col-x-2.mb-3
          v-text-field(
            :label="$t('modals.storage.filter.price_from')"
            v-model="form.price_from")
        .col-x-2.mb-3
          v-text-field(
            :label="$t('modals.storage.filter.price_to')"
            v-model="form.price_to")
      .cols-x
        .col-x-2
          v-date-field(
            :label="$t('base.date_from')"
            v-model="form.date_from")
        .col-x-2
          v-date-field(
            :label="$t('base.date_to')"
            v-model="form.date_to")
      .cols-x
        .col-x-2.mb-3
          v-text-field(
            :label="$t('modals.storage.filter.weight_from')"
            v-model="form.weight_from")
        .col-x-2.mb-3
          v-text-field(
            :label="$t('modals.storage.filter.weight_to')"
            v-model="form.weight_to")
      v-select.mb-3(
        :label="$t('base.ingredient')"
        :options="ingredientsList"
        v-model="form.ingredient_id")
      v-select(
        :label="$t('base.provider')"
        :options="providersList"
        v-model="form.provider_id")

    v-card-actions
      v-btn.mr-2(
        :loading="loading"
        @click="onApply") {{ 'actions.apply' | translate }}
      v-btn(
        text
        @click="onReset") {{ 'actions.clear' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FiltersModal',

  async mounted () {
    await this.fetchIngredientsList()
    await this.fetchProvidersList()

    const filters = this.$store.getters.suppliesFilters
    for (const key in filters) {
      this.form[key] = filters[key]
    }
  },

  data: () => ({
    form: {
      price_from: null,
      price_to: null,
      date_from: null,
      date_to: null,
      weight_from: null,
      weight_to: null,
      ingredient_id: null,
      provider_id: null
    },
    loading: false
  }),

  computed: {
    ...mapGetters([
      'ingredientsList',
      'providersList'
    ])
  },

  methods: {
    ...mapActions([
      'fetchIngredientsList',
      'fetchProvidersList',
      'applySuppliesFilters'
    ]),

    async onApply () {
      this.loading = true
      const filters = {}
      for (const key in this.form) {
        if (this.form[key] !== null) {
          filters[key] = this.form[key]
        }
      }
      await this.applySuppliesFilters(filters)
      this.loading = false
      this.$emit('close')
    },

    onReset () {
      for (const key in this.form) {
        this.form[key] = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
